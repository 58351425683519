<template>
    <div>
        <staff :client-id="$store.state.client.id"/>
    </div>
</template>

<script>
    import Staff from '@/views/setting/roles-and-users/Staff';

    export default {
        components: {Staff},
    };
</script>
